import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

function Button({ text, color, onClick }) {
  return (
    <Link>
      <button
        className={color === 'black' ? 'navigate-button black' : 'navigate-button white'}
        onClick={onClick}  // Attach the click handler passed from the parent
      >
        {text}
      </button>
    </Link>
  );
}

export default Button;
