import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Import necessary modules
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

//  Home 
import Home from './Screens/Home/Home'
// Piggytag_screen
import Piggytag_screen from './Screens/Auth/Piggytag_screen/Piggytag_screen';
// Username_pass_pop
import Username_pass_pop from './Screens/Auth/UserName_Pass_pop/Username_pass_pop';
import Final from './Screens/Final_screen/Final';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} /> 
        {/* <Route path="/email" element={<Email_pop />} />  */}
        <Route path="/username" element={<Username_pass_pop />} /> 
        <Route path="/piggytag" element={<Piggytag_screen/>} /> 
        <Route path="/final" element={<Final/>} /> 
       

        
      </Routes>
    </Router>
  );
}

export default App;
