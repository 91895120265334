import React, { useState } from 'react';
// getAuth
import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Piggytag_screen.css';
import img from '../../../Assets/Imags/logo.png'

import './animation.css';

import { FaCheck, FaTimes } from 'react-icons/fa'; // Icons for success and error
import { getAuth } from 'firebase/auth';

const db = getFirestore();

function Piggytag_screen() {
  const [piggytag, setPiggytag] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null); // State to track availability
  const [showCheckmark, setShowCheckmark] = useState(false); // For final checkmark
  const navigate = useNavigate();
  const location = useLocation();

  const { fullName, password, email } = location.state || {};

  // Validate Piggytag
  const validatePiggytag = async (value) => {
    // Clean the input
    let formattedTag = value.toLowerCase().replace(/\s+/g, '_');
    formattedTag = formattedTag.replace(/[^a-z0-9_]/g, '_');

    // If the input is empty, clear errors and availability
    if (formattedTag === '') {
      setError('Piggytag cannot be empty.');
      setIsAvailable(null); // Clear availability if there's an error
      setPiggytag(formattedTag); // Allow clearing the input
      return;
    }

    // Check if the first character is a letter or underscore
    if (/^[0-9]/.test(formattedTag)) {
      setError('Piggytag cannot start with a number.');
      setIsAvailable(null);
      return;
    }

    // Regex for valid Piggytag
    const piggytagRegex = /^[a-z0-9_]*$/;
    if (!piggytagRegex.test(formattedTag)) {
      setError('Piggytag can only contain letters, numbers, and underscores.');
      setIsAvailable(null); // Clear availability if there's an error
    } else {
      setError(''); // Clear the error
      setPiggytag(formattedTag);

      // Check if piggytag exists only after validation passes
      const piggytagExists = await checkPiggytagExists(formattedTag);
      setIsAvailable(!piggytagExists); // Update availability based on existence
    }
  };

  // Check if Piggytag exists
  const checkPiggytagExists = async (formattedTag) => {
    const q = query(collection(db, 'users'), where('piggytag', '==', `@${formattedTag}`));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if the piggytag is already taken
  };

  // Handle Piggytag Submission
 // Handle Piggytag Submission
// Handle Piggytag Submission
const handleSubmit = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    setError('You must be logged in to submit your data.');
    return;
  }

  if (!piggytag) {
    setError('Piggytag cannot be empty.');
    return;
  }

  if (error || isAvailable === false) return;

  setLoading(true);
  try {
    console.log('Submitting user data:', { fullName, email, piggytag: `@${piggytag}`, uid: user.uid });

    await addDoc(collection(db, 'users'), {
      fullName,
      password,
      email,
      piggytag: `@${piggytag}`,
      uid: user.uid,
    });
    console.log('User data stored successfully');

    // Set the Piggytag in localStorage
    localStorage.setItem('userPiggytag', `@${piggytag}`);

    // Set the flag in localStorage
    localStorage.setItem('userSignedUp', 'true');

    // Show checkmark animation
    setShowCheckmark(true);
    setTimeout(() => {
      // Redirect to the new Home page
      navigate('/final'); // Update this to match your home route
    }, 1000);
  } catch (error) {
    console.error('Error storing user data:', error);
    setError('An error occurred, please try again.');
  } finally {
    setLoading(false);
  }
};


  

  return (
    <div className="Piggytag_body">
         <div className='Topimg_piggy'>
<img  src={img} width={"150px"} alt="" />
    </div>
      <div className="heading">
        <h1>
          Choose your <span className=" piggytagtag">PiggyTag</span>
        </h1>
      </div>
      <input
        className="input piggytag_input"
        type="text"
        placeholder="piggytag"
        value={piggytag}
        onChange={(e) => validatePiggytag(e.target.value)} // Validate input as the user types
      />

      <div className="piggynote">
        <h6>*this will be your official identity on Limim, so choose wisely</h6>
      </div>
      
      {/* Error Messages */}
      {error && <p className="error_piigy">{error}</p>}

      {/* Availability check */}
      {isAvailable === false && piggytag !== '' && (
        <div className="availability-check error">
          <FaTimes className="icon-error" /> Piggytag not available
        </div>
      )}
      {isAvailable === true && piggytag !== '' && (
        <div className="availability-check success">
          <FaCheck className="icon-success" /> Piggytag available
        </div>
      )}

      {/* Checkmark animation */}
      {showCheckmark && <div className={`checkmark show`}></div>}

      <div className="modal-buttons">
        <button
          className="navigate-button piggytag_button white"
          onClick={handleSubmit}
          disabled={loading || !!error || isAvailable === false}
        >
          {loading ? 'Loading...' : 'Confirm'}
        </button>
      </div>
      <div className='Footer_body_piggytag'>
    <Link to={"mailto:prolimim@gmail.com"} ><h2>email</h2></Link>
    <Link to={"https://www.instagram.com/limimisthecoolestapp?igsh=MWZkMmc0cHprMjJtaA=="  } target="_blank"><h2>instagram</h2></Link>
    
    </div>
    </div>
  );
}

export default Piggytag_screen;
