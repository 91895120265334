import React, { useState, useEffect } from 'react';
import './Final.css';
import { Link, useNavigate } from 'react-router-dom';
import pinkimg from "../../Assets/Imags/tick1.png";
import img from '../../Assets/Imags/logo.png';

function Final() {
    const [timeLeft, setTimeLeft] = useState(10); // Timer for 10 seconds
    const navigate = useNavigate();

    // Timer logic
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        if (timeLeft === 0) {
            clearInterval(timer);
            navigate('/'); // Redirect after 10 seconds
        }

        return () => clearInterval(timer); // Clean up the timer on unmount
    }, [timeLeft, navigate]);

    return (
        <div className='final_body'>
            <div className='Topimg'>
                <img src={img} width={"150px"} alt="Logo" />
            </div>
            <div className='note'>
                <img src={pinkimg} width={"120px"} alt="Success" />
                <div className='maintext'>
                    <h3> You have successfully
                        registered your <span className='tag'>PiggyTag</span>
                    </h3>
                </div>
                <div className='second_text'>
                    <h1>You’ll now get notified about our updates!</h1>
                </div>
            </div>

            {/* Timer with circular animation */}
            <div className="circle-timer">
                <svg>
                    <circle cx="50" cy="50" r="45"></circle>
                </svg>
                <p>Redirecting in {timeLeft} seconds...</p>
            </div>

            <h2 className='next_web'>For regular updates go to this website</h2>
            <Link to={"https://limimfinal.web.app"} target='_blank'>
                <button className="navigate-button final_button">
                    Click here!
                </button>
            </Link>

            <div className='Footer_body'>
                <Link to={"mailto:prolimim@gmail.com"}><h2>email</h2></Link>
                <Link to={"https://www.instagram.com/limimisthecoolestapp?igsh=MWZkMmc0cHprMjJtaA=="} target="_blank"><h2>instagram</h2></Link>
            </div>
        </div>
    );
}

export default Final;
