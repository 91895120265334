import React, { useEffect, useState } from 'react';
import Button from '../../Utells/Button/Button';
import Email_pop from '../../Screens/Auth/Email_pop/Email_pop';
import './Home.css';
import Footer from '../../Utells/Footer/Footer';
import img from '../../Assets/Imags/logo.png'
import Carousel from '../../Utells/Carousel/Carousel';
import pinkimg from "../../Assets/Imags/tick1.png";
import { Link } from 'react-router-dom';

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [piggytag, setPiggytag] = useState(null); // State to store Piggytag
  const [modalAnimationClass, setModalAnimationClass] = useState('');

  useEffect(() => {
    // Check localStorage for the signed-up user's Piggytag
    const storedPiggytag = localStorage.getItem('userPiggytag');
    if (storedPiggytag) {
      setPiggytag(storedPiggytag); // If the user has signed up, set the Piggytag
    }
  }, []);

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);

  const handleButtonClick = () => {
    setShowModal(true);
    setModalAnimationClass('modal-pop-in');
  };

  const handleCloseModal = () => {
    setModalAnimationClass('modal-pop-out');
    setTimeout(() => {
      setShowModal(false);
    }, 500);
  };

  return (
    <div className='home_body'>
      <div className='carousel-background'>
        <Carousel type={"first"} />
        <Carousel type={"second"} />
      </div>
      <div className='Topimg_home'>
          <img src={img} width={"150px"} alt="" />
        </div>
      <div className='content'>
       

       <div className='mid_content'>
       <div className='home_main_text'>
          <h1>Hideout for the <p>coolest people</p></h1>
        </div>

        {piggytag ? ( // If the user has a Piggytag, display it instead of the button
          <div className="piggytag-display">
            <h2 className='piggy_h2'>Your registered <span className="tag_piggy">PiggyTag</span> is  <span className="real_tag">{piggytag}</span></h2>
            {/* <h2 className='next_web'>for regular updates go to this website</h2>
            <Link to={"https://limimfinal.web.app"} target='_blank'>

                <button
                    className="navigate-button final_button "

                >
                   Click here!
                </button>
            </Link> */}
            <h3 className='main_web'><span className='main_text'>Visit our  main website:</span><Link target='_blank' to={"https://limimfinal.web.app"}><span className='main_a'>Click here</span></Link></h3>
          </div>
        ) : (
          <>
            <div className='piggy_text'>
              <h2>Click here 👇</h2>
            </div>

            <button
              className='navigate-button white'
              onClick={handleButtonClick}
            >
              Join the <span className='button_tag'>clan!</span>
            </button>

            {showModal && (
              <div className="modal-overlay">
                <div className={`modal ${modalAnimationClass}`}>
                  <Email_pop handleClose={handleCloseModal} />
                </div>
              </div>
            )}
          </>
        )}
       </div>
      </div>

      <div className='Footer_body_home'>
        <Link to={"mailto:prolimim@gmail.com"}><h2>email</h2></Link>
        <Link to={"https://www.instagram.com/limimisthecoolestapp?igsh=MWZkMmc0cHprMjJtaA=="} target="_blank"><h2>instagram</h2></Link>
      </div>


    </div>
  );
};

export default Home;
