import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className='Footer_body'>
    <Link to={"mailto:prolimim@gmail.com"} ><h2>email</h2></Link>
    <Link to={"https://www.instagram.com/limimisthecoolestapp?igsh=MWZkMmc0cHprMjJtaA=="  } target="_blank"><h2>instagram</h2></Link>
    
    </div>
  )
}

export default Footer