import React, { useState,useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // For navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Username_pass_pop.css';

const db = getFirestore();

function Username_pass_pop({ email }) {
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({ fullName: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const navigate = useNavigate();
useEffect(() => {
  console.log(email);
  
  return () => {
    
  console.log(email);
  };
}, []);
  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);

    // Check if the full name starts with a letter or underscore
    if (/^[^A-Za-z_]/.test(value)) {
      setError((prevError) => ({
        ...prevError,
        fullName: 'Full name must start with a letter or underscore.',
      }));
    } else if (/[^A-Za-z0-9_. ]/.test(value)) { // Allow spaces, underscores, and periods
      setError((prevError) => ({
        ...prevError,
        fullName: 'Full name can only contain letters, numbers, spaces, ., and _.',
      }));
    } else {
      setError((prevError) => ({ ...prevError, fullName: '' }));
    }
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (value.length < 6) {
      setError((prevError) => ({
        ...prevError,
        password: 'Password must be at least 6 characters long.',
      }));
    } else {
      setError((prevError) => ({ ...prevError, password: '' }));
    }
  };

  const handleSubmit = async () => {
    if (error.fullName || error.password || !fullName || !password) return;

    try {
      setLoading(true);
      console.log('User data:', { email, fullName, password });
      setTimeout(() => {
        navigate('/piggytag', { state: { fullName, password, email } });
      }, 1000);
    } catch (error) {
      console.error('Error storing user data:', error);
      setError({ fullName: '', password: 'An error occurred, please try again.' });
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev); // Toggle password visibility
  };

  return (
    <div className="modal-overlay active">
      <div className="modal-content">
        {loading ? (
          <div className="pink-loader"></div>
        ) : (
          <>
            <h2 className="modal-title">Create account</h2>
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              className="input username_input"
              type="text"
              placeholder="Enter your full name"
              value={fullName}
              onChange={handleFullNameChange}
            />
            {error.fullName && <p className="error">{error.fullName}</p>} {/* Display error for full name */}
            <label htmlFor="password">Password</label>
            <div className="password-input-container">
              <input
                id="password"
                className="input username_input"
                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                placeholder="Create a password"
                value={password}
                onChange={handlePasswordChange}
              />
              <span
                className="password-toggle-icon"
                onClick={toggleShowPassword}
                style={{ cursor: 'pointer' }} // Adding a cursor style for better UX
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            </div>
            {error.password && <p className="error">{error.password}</p>} {/* Display error for password */}
            <div className="modal-buttons username_button">
              <button className="navigate-button black" onClick={handleSubmit}>
                Continue
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Username_pass_pop;
