import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Email_pop.css';
import './Animation.css'; // Ensure this is imported for the animation
import { auth, provider, signInWithPopup } from '../../../Config/firebase.config';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import Username_pass_pop from '../UserName_Pass_pop/Username_pass_pop'; // Import the Username popup
import { collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore utilities
import { db } from "../../../Config/firebase.config"; // Import your Firestore instance
import { doc, getDoc } from "firebase/firestore"; // Import Firestore utilities

const Email_pop = ({ handleClose }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showUsernamePopup, setShowUsernamePopup] = useState(false); // State to toggle between popups
  const [isExiting, setIsExiting] = useState(false); // State to manage exit animation
  const navigate = useNavigate();
  const authInstance = getAuth();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const checkEmailExistsInFirestore = async (email) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Returns true if any documents found
  };

  const handleEmailSubmit = async () => {
    setError('');

    if (!validateEmail(email)) {
      setError('Invalid email format');
      return;
    }

    setLoading(true);

    try {
      // Check if the email already exists in Firestore
      const emailExists = await checkEmailExistsInFirestore(email);
      if (emailExists) {
        setError('Email is already taken');
        setLoading(false);
        return;
      }

      // Attempt to create the user
      const password = 'your-default-password'; // Default password or custom logic
      await createUserWithEmailAndPassword(authInstance, email, password);
      
      // If successful, show the username popup
      setLoading(false);
      setShowUsernamePopup(true);
    } catch (error) {
      console.error('Error during email submission:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please try another one.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address.');
      } else if (error.code === 'auth/operation-not-allowed') {
        setError('Operation not allowed. Please contact support.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      // Google sign-in
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userEmail = user.email;

      // Check if the email exists in Firestore
      const emailExists = await checkEmailExistsInFirestore(userEmail);
      if (emailExists) {
        setError('Email is already taken');
        setLoading(false);
        return;
      }

      // Check if the user exists in Firestore
      const userQuery = doc(db, "users", user.uid); // Query Firestore for the user document using UID
      const userSnapshot = await getDoc(userQuery);

      if (userSnapshot.exists()) {
        // User exists in Firestore
        const userData = userSnapshot.data();
        const piggyTag = userData.piggyTag;

        // Save PiggyTag to localStorage and redirect to /final
        localStorage.setItem("userPiggytag", piggyTag);
        navigate("/final");
      } else {
        // User does not exist in Firestore, proceed to username setup
        setEmail(userEmail);
        setShowUsernamePopup(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error during Google Sign-in or Firestore check:", error);
      setError("An error occurred during Google sign-in.");
      setLoading(false);
    }
  };

  const handleCloseWithAnimation = () => {
    setIsExiting(true); // Start the exit animation
    setTimeout(() => {
      handleClose(); // Call the close handler after animation duration
    }, 10); // Duration should match the CSS animation duration
  };

  return (
    <div className={`modal-overlay active ${isExiting ? 'exiting' : ''}`}>
      {!showUsernamePopup ? (
        <div className={`modal-content ${isExiting ? 'exiting' : ''}`}>
          {loading ? (
            <div className="loading-spinner">
              <div className="pink-loader"></div>
           
            </div>
          ) : (
            <>
              <button onClick={handleCloseWithAnimation} className="close-button">
                <i className="fa-solid fa-x" style={{ color: "white", width: '20px' }}></i>
              </button>

              <h2 className="modal-title">Create account</h2>

              {/* Error message displayed above the submit button */}
              {error && <p className="error">{error}</p>}

              <button className="navigate-button google" onClick={handleGoogleSignIn}>
                <i className="fab fa-google"></i> Continue with Google
              </button>

              <div className="line-container">
                <div className="line"></div>
                <span className="or-text">or</span>
                <div className="line"></div>
              </div>
              <input
                className="input"
                type="email"
                placeholder="your@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="modal-buttons">
                <button
                  className="navigate-button email_button black"
                  onClick={handleEmailSubmit}
                  disabled={loading}
                >
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        <Username_pass_pop email={email} /> // Render the username popup here
      )}
    </div>
  );
};

export default Email_pop;
