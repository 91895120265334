import React, { useEffect, useRef } from 'react';
import './Carousel.css';

const Carousel = ({ type }) => {
  const images = [
    { src: require("../../Assets/Images_curosel/1.webp"), caption: "Come on yk who I am" },
    { src: require("../../Assets/Images_curosel/17.webp"), caption: "Vansh is the balls behind Limim’s tech and making" },  { src: require("../../Assets/Images_curosel/1.webp"), caption: "Come on yk who I am" },

    { src: require("../../Assets/Images_curosel/3.webp"), caption: "Anjan is a front end developer" },
    { src: require("../../Assets/Images_curosel/5.webp"), caption: "Isaac is a programmer ,who uploads tuts" },
    { src: require("../../Assets/Images_curosel/6.webp"), caption: "Kartik works at Microsoft" },
    { src: require("../../Assets/Images_curosel/10.webp"), caption: "Rushda is just pretty ig" },
    { src: require("../../Assets/Images_curosel/7.webp"), caption: "Laiba is writing a book !" },
    { src: require("../../Assets/Images_curosel/8.webp"), caption: "Pranay loves to sing and dreams to be the biggest singer" },
    { src: require("../../Assets/Images_curosel/12.webp"), caption: "Sharabh is a programmer and a gym freak" },
    { src: require("../../Assets/Images_curosel/17.webp"), caption: "Vansh is the balls behind Limim’s tech and making" },
    { src: require("../../Assets/Images_curosel/9.webp"), caption: "Ramesh is working on several creative projects to make his dream come true" },
    { src: require("../../Assets/Images_curosel/13.webp"), caption: "Siddharth loves to eat and code" },
    { src: require("../../Assets/Images_curosel/11.webp"), caption: "Senthil is searching for a product to work on" },
    { src: require("../../Assets/Images_curosel/14.webp"), caption: "Soaham cooks with AI " },
    { src: require("../../Assets/Images_curosel/16.webp"), caption: "Umaid does cool football tricks" },
    { src: require("../../Assets/Images_curosel/3.webp"), caption: "Anjan is a front end developer" },
    { src: require("../../Assets/Images_curosel/15.webp"), caption: "Tilak uploads helpful tips to grow in life on YouTube" },
    { src: require("../../Assets/Images_curosel/5.webp"), caption: "Isaac is a programmer ,who uploads tuts" },
    { src: require("../../Assets/Images_curosel/6.webp"), caption: "Kartik works at Microsoft" },
    { src: require("../../Assets/Images_curosel/7.webp"), caption: "Laiba is writing a book !" },
    { src: require("../../Assets/Images_curosel/8.webp"), caption: "Pranay loves to sing and dreams to be the biggest singer" },
    { src: require("../../Assets/Images_curosel/9.webp"), caption: "Ramesh is working on several creative projects to make his dream come true" },
    { src: require("../../Assets/Images_curosel/12.webp"), caption: "Sharabh is a programmer and a gym freak" },
    { src: require("../../Assets/Images_curosel/14.webp"), caption: "Soaham cooks with AI " },
    { src: require("../../Assets/Images_curosel/10.webp"), caption: "Rushda is just pretty ig" },
    { src: require("../../Assets/Images_curosel/13.webp"), caption: "Siddharth loves to eat and code" },
    { src: require("../../Assets/Images_curosel/15.webp"), caption: "Tilak uploads helpful tips to grow in life on YouTube" },
    { src: require("../../Assets/Images_curosel/16.webp"), caption: "Umaid does cool football tricks" },
    { src: require("../../Assets/Images_curosel/11.webp"), caption: "Senthil is searching for a product to work on" },
  ];

  const trackRef = useRef(null);

  useEffect(() => {
    const track = trackRef.current;
    let scrollSpeed = type === 'second' ? -1.3 : 1.3; // Reverse scroll for "second" type
    let scrollPosition = 0;

    const moveCarousel = () => {
      scrollPosition -= scrollSpeed;

      if (Math.abs(scrollPosition) >= track.scrollWidth / 2) {
        scrollPosition = 0; // Reset position after one full loop
      }

      track.style.transform = `translateX(${scrollPosition}px)`;

      requestAnimationFrame(moveCarousel);
    };

    requestAnimationFrame(moveCarousel);

    return () => cancelAnimationFrame(moveCarousel);
  }, [type]);

  return (
    <div className={type === "first" ? "carousel" : "Second"}>
      <div className="carousel-track" ref={trackRef}>
        {images.map((image, index) => (
          <div className="carousel-slide" key={index}>
            <img src={image.src} alt={`Slide ${index + 1}`} />
            <div className="carousel-caption">
              <p>{image.caption}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
