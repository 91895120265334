import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup as firebaseSignInWithPopup } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhhgeK1K5Z9PAl73saaZBy2D9JDUnlKCs",
  authDomain: "limimapp.firebaseapp.com",
  projectId: "limimapp",
  storageBucket: "limimapp.appspot.com",
  messagingSenderId: "471808343539",
  appId: "1:471808343539:web:651117836712d9c948a686",
  measurementId: "G-Q9CT7HK9XT"
};

// Initialize Firebase
const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

// Wrapper function for signInWithPopup
const signInWithPopup = async () => {
  return await firebaseSignInWithPopup(auth, provider);
};

// Export the initialized instances and the wrapper function
export { app, auth, db, analytics, provider, signInWithPopup };
